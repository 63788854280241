import { GridProps } from '@mui/material'
import React from 'react'
import { RegisterOptions } from 'react-hook-form'
import { AddressTextFieldProps } from '../AddressTextField'
import { DatePickerProps } from '../DatePicker'
import { DateTimePickerComponentProps } from '../DateTimePicker'
import { DropdownProps } from '../Dropdown'
import { FileUploadProps } from '../FileUpload'
import { PercentageSliderProps } from '../PercentageSlider'
import { FormattedTextFieldProps } from '../FormattedTextField'
import { InputDecoratorProps } from '../InputDecorator/InputDecorator'
import { RadioSelectProps } from '../RadioSelect'
import { CheckboxProps } from '../Checkbox'
import { MultiCheckboxProps } from '../MultiCheckbox'

export enum InputType {
  Text,
  RadioSelect,
  Dropdown,
  DatePicker,
  Address,
  Section,
  FileUpload,
  PercentageSlider,
  CustomInput,
  CustomComponent,
  IndexedComponent,
  Checkbox,
  Multiform,
  MultiCheckbox,
  DateTimePicker,
}

export type MultiformProps = {
  title: string
  name: string
  inputConfigs: AnyInputConfig[]
  minForms: number
  maxForms: number
  addFormComponent: React.FC<{}>
  minusFormComponent: React.FC<{}>
  incrementText: string
  decrementText: string
  horizontal?: boolean
  itemContainerProps?: GridProps
}

export type SectionProps = {
  title: string
  sectionGridProps?: GridProps
  collapsible?: boolean
  collapsibleHorizontal?: boolean
  inputConfigs: AnyInputConfig[]
}

export type SectionState = {
  name: string
  title: string
  status: SectionStatus
}

export enum SectionStatus {
  INCOMPLETE = 'incomplete',
  PARTIAL = 'partial',
  COMPLETE = 'complete',
}

export type CustomComponentProps = {
  component?: React.ReactNode
}

export type IndexedComponentProps = {
  components?: React.ReactNode[]
}

export type CustomInputProps = {
  component: React.FC<{
    onBlur: () => void
    onChange: (value: any) => void
    value: any
    error?: boolean
    helperText?: string
  }>
  componentProps: any
}

type InputTypeToSpecificProps = {
  [InputType.Address]: AddressTextFieldProps
  [InputType.Checkbox]: CheckboxProps
  [InputType.CustomComponent]: CustomComponentProps
  [InputType.CustomInput]: CustomInputProps
  [InputType.DatePicker]: DatePickerProps
  [InputType.Dropdown]: DropdownProps
  [InputType.FileUpload]: FileUploadProps
  [InputType.MultiCheckbox]: MultiCheckboxProps
  [InputType.Multiform]: MultiformProps
  [InputType.PercentageSlider]: PercentageSliderProps
  [InputType.RadioSelect]: RadioSelectProps
  [InputType.Section]: SectionProps
  [InputType.Text]: FormattedTextFieldProps
  [InputType.MultiCheckbox]: MultiCheckboxProps
  [InputType.DateTimePicker]: DateTimePickerComponentProps
  [InputType.IndexedComponent]: IndexedComponentProps
}

export type InputConfig<
  Type extends InputType & keyof InputTypeToSpecificProps
> = {
  name: string
  type: Type
  labelText?: string | React.ReactNode
  labelOptionalText?: string
  customError?: string
  defaultValue?: any
  validation?: 'fullName' | 'email' | 'year' | 'phone'
  gridItemProps?: GridProps
  required?: boolean
  rules?: RegisterOptions
  renderIf?: (
    values: { [key: string]: any },
    multiformParentIndex?: number
  ) => boolean
  props: Partial<InputTypeToSpecificProps[Type]>
  mobileProps?: Partial<InputTypeToSpecificProps[Type]>
  inputDecoratorProps?: Partial<InputDecoratorProps>
  inputDecoratorMobileProps?: Partial<InputDecoratorProps>
  parentName?: string
}

export type AnyInputConfig =
  | InputConfig<InputType.Address>
  | InputConfig<InputType.Checkbox>
  | InputConfig<InputType.CustomComponent>
  | InputConfig<InputType.CustomInput>
  | InputConfig<InputType.DatePicker>
  | InputConfig<InputType.Dropdown>
  | InputConfig<InputType.FileUpload>
  | InputConfig<InputType.MultiCheckbox>
  | InputConfig<InputType.Multiform>
  | InputConfig<InputType.PercentageSlider>
  | InputConfig<InputType.RadioSelect>
  | InputConfig<InputType.Section>
  | InputConfig<InputType.Text>
  | InputConfig<InputType.MultiCheckbox>
  | InputConfig<InputType.DateTimePicker>
  | InputConfig<InputType.IndexedComponent>
